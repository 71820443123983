import React, { useEffect, useState, useContext } from "react";
import Table from "react-bootstrap/esm/Table";
import { loadTransactions } from "../context/functions/AdminFunction";
import { Form, InputGroup } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

let interval = null;
const TransactionsAssigned = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState("");

  useEffect(() => {
    getTransactionsFunc();
  }, [currentPage, searchVal, sortColumn, sortType]);

  const getTransactionsFunc = async () => {
    const temp = await loadTransactions(
      "assignedProps",
      searchVal,
      currentPage,
      sortType,
      sortColumn
    );

    setTotalPages(temp?.totalPages);
    setTransactions(temp?.transactions);
    console.log(temp);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };

  const debouncedSearch = (value) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      setSearchVal(value);
      setcurrentPage(1);
    }, 500);
  }

  const handleSorting = (name) => {
    if (name == sortColumn) {
      setSortType(prev => prev == 'asc' ? 'desc' : 'asc')
    } else {
      setSortType('asc')
    }
    console.log(name)
    setcurrentPage(1)
    setSortColumn(name);
  }

  return (
    <>
      <div className="header_table d-flex justify-content-between align-items-center">
        <h1>Transactions</h1>
        <div className="searchbar_table">
          <div className="d-flex align-items-center gap-4">
            <InputGroup className="input_table_header">
              <InputGroup.Text id="basic-addon1">
                <HiMagnifyingGlass />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
        </div>
      </div>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Landlord Name</th>
            <th>Tenant Name</th>
            <th>LL Email</th>
            <th>LL IBAN Number</th>
            <th>Address</th>
            <th>Amount</th>
            <th>Payment Method</th>
            <th>Transaction Id</th>
            <th>Type</th>
            <th name="created_at" onClick={e => handleSorting("created_at")}>Payment Date {sortColumn == "created_at" ? sortType == "asc" ? <FaSortUp /> : <FaSortDown /> : <FaSort />}</th>
            <th name="processing_date" onClick={e => handleSorting("processing_date")}>Payment For {sortColumn == "processing_date" ? sortType == "asc" ? <FaSortUp /> : <FaSortDown /> : <FaSort />}</th>
          </tr>
        </thead>
        <tbody>
          {transactions &&
            transactions.map((val, index) => (
              <tr key={index}>
                <td>
                  {val.landlord.fname_companyName || ""}{" "}
                  {val.landlord.lname || ""}
                </td>
                <td>
                  {val.tenant.fname || ""}{" "}
                  {val.tenant.lname || ""}
                </td>
                <td>{val?.landlord?.email}</td>
                <td>{val?.landlord?.iban_num}</td>
                <td>{val.address}</td>
                <td>{val.amount}</td>
                <td>{val?.payment_method}</td>
                <td>{val?.transaction_id}</td>
                <td>{val.type}</td>
                <td>{val.created_at && moment(val.created_at).format("YYYY/MM/DD")}</td>
                <td>{val.processing_date && moment(val.processing_date).format("YYYY/MM/DD")}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="">
        <button
          onClick={() => handlePageChange(+currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => handlePageChange(+currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default TransactionsAssigned;
